import API from '@/plugin/points-manager/service/api'
import { ElMessage } from 'element-plus'
export default {
  data() {
    return {}
  },
  methods: {
    async uploadFileMixin(file, type, beforeCallBack, afterCallBack) {
      if (typeof beforeCallBack === 'function') {
        beforeCallBack()
      }
      let res = await API.uploadFileAPI(
        {
          file: file.file,
          filename: file.fileName,
        },
        { businessType: type },
      ).catch(err => {
        ElMessage.error(err.message)
      })
      if (res.code == 200) {
        if (typeof beforeCallBack === 'function') {
          afterCallBack(res.data.fileUrl)
        }
      } else {
        ElMessage.error(res.msg)
      }
    },
    async uploadfileRuleMixin(uploadRule, vueVersion) {
      let res = await API.uploadFileRuleAPI({
        businessType: uploadRule.businessType,
      })
      this.uploadRule = {
        ...res.data,
        businessType: uploadRule.businessType,
      }
    },
  },
}
